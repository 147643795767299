<template>
  <v-container class="response__container">
    <v-list-item-content></v-list-item-content>
    <b-list-group>
      <b-list-group-item>
        <!-- <div v-if="paymentDetails.status=='paid'" class="text-center" style="color:green;">
          <b-alert  variant="success">Thank you for your contribution.</b-alert>
        </div>
        
        <div v-else class="text-center " style="color:red;">
          <b-alert variant="danger">It looks like the contribution did not get through. Please find the details below.</b-alert>
        </div> -->

        <div class="text-center">
          <b-alert
            v-if="paymentDetails.status == 'success'"
            show
            variant="success"
            >Thank you for your contribution.
          </b-alert>
          <b-alert v-else show variant="danger"
            >Fetching Payment Status, please check the details below.
          </b-alert>
          <!-- <b-alert v-if="paymentDetails.status !=='paid'" show variant="danger">Loading payment status from payment gateway... </b-alert> -->
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-center align-items-center align-self-stretch"
      >
        <label>Donor Name:</label>
        <label class="p-2"
          ><b>&nbsp;{{ contributionDetails.donorName }}</b></label
        >
      </b-list-group-item>

      <div v-show="paymentDetails.status == 'success'">
        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>{{ panelItems.transId }}:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.transcation_id }}</b></label
          >
        </b-list-group-item>
      </div>

      <b-list-group-item>
        <v-row>
          <v-col class="text-center" cols="6" sm="6" lg="6">
            <b-button pill variant="outline-secondary" v-on:click="home"
              >CONTRIBUTE AGAIN</b-button
            >
          </v-col>
          <v-col class="text-center" cols="6" sm="6" lg="6">
            <!-- <v-btn
    :loading="loading3"
    :disabled="loading3"
    color="blue-grey"
    class="ma-2 white--text"
    @click="receipt = 'loading3'"
     v-show="paymentDetails.status=='paid'"
  >
    Download Receipt
    <v-icon right dark>mdi-cloud-download</v-icon>
  </v-btn> -->
            <b-button
              v-show="paymentDetails.status == 'success'"
              pill
              variant="success"
              v-on:click="receipt"
              ref="receipt-spinner"
              >PROVISIONAL RECEIPT
              <v-icon right dark>mdi-cloud-download</v-icon>
            </b-button>
          </v-col>
        </v-row>
      </b-list-group-item>
    </b-list-group>

    <b-list-group>
      <v-card>
        <v-card-title class="subheading font-weight-bold justify-content-center"
          >Contribution Details
        </v-card-title>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Donation Id:</label>
          <label class="p-2"
            ><b>&nbsp;{{ contributionDetails.donationId }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Project Name:</label>
          <label class="p-2"
            ><b>&nbsp;{{ contributionDetails.projectName }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Donation Amount:</label>
          <label class="p-2"
            ><b
              >&nbsp;{{ contributionDetails.donationAmount | currency }}</b
            ></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Status: &nbsp;</label>
          <b-alert
            v-if="paymentDetails.status == 'success'"
            variant="success"
            class="mb-2"
            show
          >
            {{ paymentDetails.status }}
          </b-alert>
          <b-alert v-else variant="danger" class="mb-2" show>
            &nbsp;{{ paymentDetails.status }}</b-alert
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Date:</label>
          <label class="p-2"
            ><b>&nbsp;{{ contributionDetails.createdAt }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Receipt Id:</label>
          <label v-if="this.contributionDetails.receiptId != null" class="p-2"
            ><b>&nbsp;{{ this.contributionDetails.receiptId }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Transaction Id:</label>
          <label v-if="contributionDetails.transcationId != null" class="p-2"
            ><b>&nbsp;{{ contributionDetails.transcationId }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>
      </v-card>
    </b-list-group>

    <b-list-group>
      <v-card>
        <v-card-title class="subheading font-weight-bold justify-content-center"
          >Personal Details
        </v-card-title>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Gender:</label>
          <label v-if="paymentDetails.gender != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.gender }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Date of Birth:</label>
          <label v-if="paymentDetails.dob != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.dob }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Address:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.address }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>City:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.city }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>District:</label>
          <label v-if="paymentDetails.district != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.district }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Postal Code:</label>
          <label v-if="paymentDetails.postal_code != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.postal_code }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>State:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.state }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Country:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.country }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>PAN:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.pan_no }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Mobile No:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.phone }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Email:</label>
          <label v-if="paymentDetails.email != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.email }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Referred By:</label>
          <label v-if="paymentDetails.referred_by != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.referred_by }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>
      </v-card>
    </b-list-group>
  </v-container>
</template>

<style scoped>
.response__container {
  max-width: 55%;
}

@media screen and (max-width: 600px) {
  .response__container {
    max-width: 100%;
  }
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

Vue.filter("currency", function(value) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

export default {
  name: "donationView",
  data() {
    return {
      panelItems: [],
      paymentDetails: "",
      donations: [],
      paymentStatusDetail: {
        status: "",
        status_id: ""
      },
      contributionDetails: {},
      donationId: localStorage.getItem("storedData")
    };
  },
  created() {
    //initialize fetchDonation function
    this.getPageContents(), this.fetchDonation();
  },

  methods: {
    getPageContents() {
      ApiService.get("/organisation").then(({ data }) => {
        this.panelItems = data["panelItems"];
        localStorage.setItem("PanelItems", JSON.stringify(this.panelItems));
      });
    },
    // Function to Fetch Data from Donation Single View API
    fetchDonation() {
      ApiService.get("donarDonationview/" + this.donationId).then(
        ({ data }) => {
          this.paymentData = data;
          this.paymentDetails = this.paymentData.donationDetails[0];
          var donorName = this.paymentDetails.donor_name;
          var projName = this.paymentDetails.project_name;
          var createdAt = this.paymentDetails.created_at;
          console.log(this.paymentDetails);
          var transcationId = this.paymentDetails.transcation_id;
          this.contributionDetails.donorId = this.paymentDetails.donorId;
          this.contributionDetails.donorName = donorName;
          this.contributionDetails.projectName = projName;
          this.contributionDetails.projectId = this.paymentDetails.projectId;
          this.contributionDetails.createdAt = createdAt;
          this.contributionDetails.transcationId = transcationId;
          this.contributionDetails.donationId = this.paymentDetails.donation_id;
          this.contributionDetails.donationAmount = this.paymentDetails.amount;
          this.contributionDetails.receiptId = this.paymentDetails.receipt_id;

          //Check Receipt already there in DB
          ApiService.get(
            "/checkReceipt",
            this.contributionDetails.donationId
          ).then(({ data }) => {
            this.receiptPresent = data;
            if (this.receiptPresent.status == "TRUE") {
              this.receiptId = this.receiptPresent.Receipt[0].receipt_id;
              //Email Receipt to Donor
              // ApiService.get("/userReceiptEmail", this.receiptId);
            } else {
              this.receiptId = null;
            }
            this.contributionDetails.receiptId = this.receiptId;

            // //If Status == False (Receipt data is present, on Page Refresh not updating DB)
            if (this.receiptPresent.status == "FALSE") {
              this.paymentStatusDetail.status = this.paymentDetails.status;
              //Receipt Generation (Data store in DB)
              this.receiptData.transactionId = this.contributionDetails.transcationId;
              this.receiptData.donationId = this.contributionDetails.donationId;
              this.receiptData.projectId = this.contributionDetails.projectId;
              this.receiptData.donorId = this.contributionDetails.donorId;
              this.receiptData.paymentType = "Online";
              this.receiptData.paymentMethodType = this.paymentDetails.payment_method_type;
            }
          });
        }
      );
    },
    //Get back to donations list page on click back button
    backToDonations: function() {
      //redirect to donations page
      this.$router.push("/donations");
    },

    //Downloading receipt
    receipt() {
      const submitButton = this.$refs["receipt-spinner"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      ApiService.getPDF("userGetReceipt", this.receiptId).then(response => {
        var file = new Blob([response.data], {
          type: "application/pdf"
        });
        var filename = this.receiptId + ".pdf";
        const pdfData = window.URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = pdfData;
        link.download = filename;
        link.click();
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right",
          "disabled"
        );
      });
    },

    declaration() {
      //ApiService.setHeader();
      ApiService.getPDF("getSelfDeclaration", this.receiptId).then(response => {
        var file = new Blob([response.data], {
          type: "application/pdf"
        });
        var filename = this.receiptId + "-declartion.pdf";
        const pdfData = window.URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = pdfData;
        link.download = filename;
        link.click();
      });
    },

    home() {
      this.$router.push({
        name: "contribute"
      });
    }
  }
};
</script>
